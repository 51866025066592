import '../css/app.css';
import './bootstrap';

import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createRoot } from 'react-dom/client';
import { Toaster } from 'sonner'
import posthog from 'posthog-js'
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://2adc29f2087deb4af9bdf8a3633c4ff0@o4507442318475264.ingest.us.sentry.io/4508387682287616",
    integrations: [],
  });

// Initialize PostHog
if (typeof window !== 'undefined') {
    posthog.init('phc_zRSaDEgboqHVZhnPGxYI5gYhynsjtTSPe9COXa0LUO4', {
        api_host: 'https://us.i.posthog.com',
        capture_pageview: false,
        persistence: 'localStorage',
        autocapture: true,
        session_recording: {
            maskInputOptions: {
                password: true,
                email: false,
                number: false,
            },
        },
    })
}

// @ts-ignore
declare global {
    interface Window {
        route: any;
        posthog: typeof posthog;
    }
}

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: async (name) => {
        // Using dynamic imports for code splitting
        const pages = import.meta.glob<any>('./Pages/**/*.tsx')
        const page = await pages[`./Pages/${name}.tsx`]()

        // Capture page view after navigation
        if (typeof window !== 'undefined') {
            posthog.capture('$pageview')
        }

        return page.default
    },
    setup({ el, App, props }) {
        const root = createRoot(el);

        // Initialize Ziggy routes
        if (typeof window !== 'undefined' && window.route === undefined) {
            window.route = (name: string, params: any = {}, absolute: boolean = true) => {
                return route(name, params, absolute);
            };
        }

        // Make PostHog available globally
        if (typeof window !== 'undefined') {
            window.posthog = posthog;
        }

        root.render(
            <>
                <App {...props} />
                <Toaster position="top-right" />
            </>
        );
    },
    progress: {
        color: '#4B5563',
        delay: 250,
        includeCSS: true,
        showSpinner: true,
    },
});
